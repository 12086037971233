import { format, parseJSON } from "date-fns";
import { camelize } from "humps";
import get from "lodash/get";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import { AutoNewWindowsValue } from "components/common/MenuBuilder/constants";
import { useListPluginsQuery } from "services/api/plugins";
import { useGetProjectStatusQuery } from "services/api/projects";
import { useAppDispatch, useAppSelector } from "stores";
import { selectBottomTabBar } from "stores/features/appBuilder/bottomTabBar";
import { selectBranding } from "stores/features/appBuilder/branding";
import {
  buildUpdated,
  selectAndroidBuild,
  selectBuild,
  selectIosBuild,
} from "stores/features/appBuilder/build";
import { selectLicense } from "stores/features/appBuilder/license";
import { selectLinkHandling } from "stores/features/appBuilder/linkHandling";
import {
  AppBuilderNativePluginsState,
  GoogleNativePlugin,
  selectNativePlugins,
} from "stores/features/appBuilder/nativePlugins";
import { selectPermissions } from "stores/features/appBuilder/permissions";
import {
  AppBuilderPushNotificationsState,
  selectPushNotifications,
} from "stores/features/appBuilder/pushNotifications";
import { selectTopNavigationBar } from "stores/features/appBuilder/topNavigationBar";
import { selectAppConfig } from "stores/features/appConfig";
import {
  hasChangeAndroidOrIosKeys,
  selectBuildChanges,
  selectBuilderDiff,
} from "stores/features/meta";
import { selectPrivateKey } from "stores/features/project";
import { BuilderConfigInput } from "utils/appBuilder";
import { SECTION } from "utils/constants";
import { getBuildError } from "utils/errors";
import urls from "utils/urls";

export type BuildButtonType =
  | "all"
  | "androidBinary"
  | "androidSource"
  | "androidAppetize"
  | "iosSource"
  | "iosBinary"
  | "iosAppetize";

interface IncrementalBuildSource {
  type: BuildButtonType;
  isRebuild?: boolean;
}

export const useIncrementalBuild = (source: IncrementalBuildSource) => {
  const androidBuild = useAppSelector(selectAndroidBuild);
  const hasChanges = useAppSelector(selectBuildChanges);
  const iosBuild = useAppSelector(selectIosBuild);
  const build = useAppSelector(selectBuild);
  const { t } = useTranslation();
  const buildDiff = useAppSelector(selectBuilderDiff);
  const hasChangesPlatform = hasChangeAndroidOrIosKeys(buildDiff);
  const privateKey = useAppSelector(selectPrivateKey);

  const { type, isRebuild } = source;

  const builderConfig: BuilderConfigInput = useMemo(() => {
    switch (type) {
      case "iosAppetize":
        return {
          platform: "ios",
          status: "queued",
          type: { appetize: "buildRequested" },
          hasChanges: hasChanges ? hasChangesPlatform : false,
        };
      case "iosSource":
        return {
          platform: "ios",
          status: "queued",
          type: { source: "buildRequested" },
          hasChanges: hasChanges ? hasChangesPlatform : false,
        };
      case "iosBinary":
        return {
          platform: "ios",
          status: "queued",
          type: { binary: "buildRequested" },
          hasChanges: hasChanges ? hasChangesPlatform : false,
        };
      case "androidBinary":
        return {
          platform: "android",
          status: "queued",
          type: { binary: "buildRequested" },
          hasChanges: hasChanges ? hasChangesPlatform : false,
        };
      case "androidAppetize":
        return {
          platform: "android",
          status: "queued",
          type: { appetize: "buildRequested" },
          hasChanges: hasChanges ? hasChangesPlatform : false,
        };
      case "androidSource":
        return {
          platform: "android",
          status: "queued",
          type: { source: "buildRequested" },
          hasChanges: hasChanges ? hasChangesPlatform : false,
        };
      default:
        return {
          status: "queued",
          type: {
            appetize: "buildRequested",
            source: "buildRequested",
            binary: "buildRequested",
          },
        };
    }
  }, [hasChanges, hasChangesPlatform, type]);

  const buildLabel = useMemo(() => {
    const prefix = `containers.rightPanel.preview.${isRebuild ? "re" : ""}`;
    if (type === "androidBinary") {
      return t(`${prefix}buildAndroidBinaries`);
    } else if (type === "androidSource") {
      return t(`${prefix}buildAndroidSource`);
    } else if (type === "iosSource") {
      return t(`${prefix}buildIosSource`);
    } else if (type === "iosBinary") {
      return t(`${prefix}buildIosBinary`);
    } else if (type === "iosAppetize") {
      return t(`${prefix}build`);
    } else if (type === "androidAppetize") {
      return t(`${prefix}build`);
    } else {
      return t(`${prefix}buildAll`);
    }
  }, [isRebuild, t, type]);

  const platform = useMemo(() => {
    if (type.startsWith("ios")) return "ios";
    return "android";
  }, [type]);

  const buildProgress = useMemo(() => {
    if (platform === "ios") {
      if (iosBuild.status === "queued") return 0;
      return iosBuild.progress;
    }
    if (androidBuild.status === "queued") return 0;
    return androidBuild.progress;
  }, [androidBuild, iosBuild, platform]);

  const isDisabled = useMemo(() => {
    const iosBuilding = ["queued", "building"].includes(iosBuild.status);
    const androidBuilding = ["queued", "building"].includes(
      androidBuild.status
    );
    switch (type) {
      case "iosAppetize":
        return iosBuilding && iosBuild.type.appetize !== "buildRequested";
      case "iosBinary":
        return iosBuilding && iosBuild.type.binary !== "buildRequested";
      case "iosSource":
        return iosBuilding && iosBuild.type.source !== "buildRequested";
      case "androidBinary":
        return androidBuilding && androidBuild.type.binary !== "buildRequested";
      case "androidAppetize":
        return (
          androidBuilding && androidBuild.type.appetize !== "buildRequested"
        );
      case "androidSource":
        return androidBuilding && androidBuild.type.source !== "buildRequested";
      default:
        return !build.license.buildActive;
    }
  }, [androidBuild, iosBuild, build.license.buildActive, type]);

  const progressLabel = useMemo(() => {
    const isBuildingBothAndroid =
      androidBuild.type.source === "buildRequested" &&
      androidBuild.type.binary === "buildRequested";

    if (buildProgress < 20) {
      return t("containers.rightPanel.preview.appInQueue");
    } else if (buildProgress >= 80) {
      return t("containers.rightPanel.preview.uploadingApp");
    } else if (["iosSource", "iosAppetize"].includes(type)) {
      return t("containers.rightPanel.preview.buildingIos");
    } else if (
      ["androidSource", "androidAppetize"].includes(type) ||
      isBuildingBothAndroid
    ) {
      return t("containers.rightPanel.preview.buildingAndroid");
    } else if (type === "androidBinary") {
      return t("containers.rightPanel.preview.buildingAndroidBundle");
    } else if (type === "iosBinary") {
      return t("containers.rightPanel.preview.buildingIpa");
    } else if (buildProgress < 50) {
      return t("containers.rightPanel.preview.buildingIos");
    } else {
      return t("containers.rightPanel.preview.buildingAndroid");
    }
  }, [
    androidBuild.type.source,
    androidBuild.type.binary,
    buildProgress,
    type,
    t,
  ]);

  const errors = useMemo(() => {
    if (type === "all") {
      return { android: androidBuild.error, ios: iosBuild.error };
    }
    if (type === "androidAppetize") {
      return { android: androidBuild.error, ios: null };
    }
    if (type === "iosAppetize") {
      return { android: null, ios: iosBuild.error };
    }
    return { android: null, ios: null };
  }, [androidBuild.error, iosBuild.error, type]);

  const showOutdatedWarning = useMemo(() => {
    if (type === "iosAppetize") {
      return (
        iosBuild.status === "draft" ||
        ["expired", "outdated"].includes(iosBuild.type.appetize || "")
      );
    } else if (type === "androidAppetize") {
      return (
        androidBuild.status === "draft" ||
        ["expired", "outdated"].includes(androidBuild.type.appetize || "")
      );
    } else {
      return false;
    }
  }, [androidBuild, iosBuild, type]);

  const isIosAppetizeOutdated = useMemo(() => {
    if (privateKey) {
      return (
        iosBuild.status === "draft" ||
        ["expired", "outdated"].includes(iosBuild.type.appetize || "")
      );
    } else return false;
  }, [iosBuild.status, iosBuild.type.appetize, privateKey]);

  const isAndroidAppetizeOutdated = useMemo(() => {
    if (privateKey) {
      return (
        androidBuild.status === "draft" ||
        ["expired", "outdated"].includes(androidBuild.type.appetize || "")
      );
    } else return false;
  }, [androidBuild.status, androidBuild.type.appetize, privateKey]);

  const showProgress = useMemo(() => {
    const iosBuilding = ["queued", "building"].includes(iosBuild.status);
    const androidBuilding = ["queued", "building"].includes(
      androidBuild.status
    );
    switch (type) {
      case "iosAppetize":
        return iosBuilding && iosBuild.type.appetize === "buildRequested";
      case "iosBinary":
        return iosBuilding && iosBuild.type.binary === "buildRequested";
      case "iosSource":
        return iosBuilding && iosBuild.type.source === "buildRequested";
      case "androidBinary":
        return androidBuilding && androidBuild.type.binary === "buildRequested";
      case "androidAppetize":
        return (
          androidBuilding && androidBuild.type.appetize === "buildRequested"
        );
      case "androidSource":
        return androidBuilding && androidBuild.type.source === "buildRequested";
      default:
        return false;
    }
  }, [androidBuild, iosBuild, type]);

  return {
    builderConfig,
    buildLabel,
    buildProgress,
    errors,
    isDisabled,
    progressLabel,
    showOutdatedWarning,
    showProgress,
    isIosAppetizeOutdated,
    isAndroidAppetizeOutdated,
  };
};

export const useCentralizedBuilder = () => {
  const androidBuild = useAppSelector(selectAndroidBuild);
  const iosBuild = useAppSelector(selectIosBuild);
  const privateKey = useAppSelector(selectPrivateKey);
  const dispatch = useAppDispatch();

  const { refetch: refetchProjectStatus } = useGetProjectStatusQuery(
    { privateKey },
    { skip: !privateKey }
  );

  const isPolling = useMemo(() => {
    const iosBuilding = ["queued", "building"].includes(iosBuild.status);
    const androidBuilding = ["queued", "building"].includes(
      androidBuild.status
    );
    return iosBuilding || androidBuilding;
  }, [androidBuild.status, iosBuild.status]);

  // Create manual polling function instead of using the built-in rtk.query polling interval
  useEffect(() => {
    let interval: NodeJS.Timer;

    if (isPolling) {
      interval = setInterval(() => {
        refetchProjectStatus();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isPolling, refetchProjectStatus]);

  // Fetch error logs for iOS
  useEffect(() => {
    (async () => {
      const error = await getBuildError(iosBuild);
      dispatch(buildUpdated({ iosBuild: { error } }));
    })();
  }, [dispatch, iosBuild]);

  // Fetch error logs for Android
  useEffect(() => {
    (async () => {
      const error = await getBuildError(androidBuild);
      dispatch(buildUpdated({ androidBuild: { error } }));
    })();
  }, [dispatch, androidBuild]);
};

export const useBuildStatus = () => {
  const androidBuild = useAppSelector(selectAndroidBuild);
  const iosBuild = useAppSelector(selectIosBuild);
  const build = useAppSelector(selectBuild);

  const isBuildingIosAppetize = useMemo(
    () =>
      ["queued", "building"].includes(iosBuild.status) &&
      iosBuild.type.appetize === "buildRequested",
    [iosBuild]
  );

  const isBuildingAndroidAppetize = useMemo(
    () =>
      ["queued", "building"].includes(androidBuild.status) &&
      androidBuild.type.appetize === "buildRequested",
    [androidBuild]
  );

  const isBuildingBothIos = useMemo(
    () =>
      ["queued", "building"].includes(iosBuild.status) &&
      iosBuild.type.source === "buildRequested" &&
      iosBuild.type.binary === "buildRequested",
    [iosBuild]
  );

  const isBuildingBothAndroid = useMemo(
    () =>
      ["queued", "building"].includes(androidBuild.status) &&
      androidBuild.type.source === "buildRequested" &&
      androidBuild.type.binary === "buildRequested",
    [androidBuild]
  );

  const getLastBuiltDate = useCallback((downloadLink, lastBuiltDate) => {
    if (downloadLink && lastBuiltDate) {
      const date = parseJSON(lastBuiltDate);
      return format(date, "MMM dd, yyyy hh:mm aa");
    }
    return null;
  }, []);

  const iosSourceLastBuiltDate = getLastBuiltDate(
    build.downloadLinks.iosSource,
    build.iosLastBuiltDate?.source
  );

  const androidSourceLastBuiltDate = getLastBuiltDate(
    build.downloadLinks.androidSource,
    build.androidLastBuiltDate?.source
  );

  const androidBinaryLastBuiltDate = getLastBuiltDate(
    build.downloadLinks.androidLink && build.downloadLinks.androidAppBundleLink,
    build.androidLastBuiltDate?.binary
  );

  const iosBuildSourceOutdated =
    build.iosBuild.type.source === "outdated" ||
    build.iosBuild.type.source === "expired";
  const androidBuildBinariesOutdated =
    build.androidBuild.type.binary === "outdated" ||
    build.androidBuild.type.binary === "expired";
  const androidBuildSourceOutdated =
    build.androidBuild.type.source === "outdated" ||
    build.androidBuild.type.source === "expired";

  const allDevicesSourceOutdated =
    iosBuildSourceOutdated &&
    androidBuildBinariesOutdated &&
    androidBuildSourceOutdated;

  return {
    isBuildingAll: isBuildingBothIos && isBuildingBothAndroid,
    isBuildingBothAndroid,
    isBuildingBothIos,
    showAndroidBinary:
      (build.downloadLinks.androidLink ||
        build.downloadLinks.androidAppBundleLink) &&
      androidBuild.type.binary !== "buildRequested",
    showAndroidSource:
      build.downloadLinks.androidSource &&
      androidBuild.type.source !== "buildRequested",
    showIosSource:
      build.downloadLinks.iosSource &&
      iosBuild.type.source !== "buildRequested",
    androidBinaryLastBuiltDate,
    androidSourceLastBuiltDate,
    iosSourceLastBuiltDate,
    isBuildingAndroidAppetize,
    isBuildingIosAppetize,
    iosBuildSourceOutdated,
    androidBuildBinariesOutdated,
    androidBuildSourceOutdated,
    allDevicesSourceOutdated,
  };
};

export const useBuildIssues = () => {
  const appConfig = useAppSelector(selectAppConfig);
  const branding = useAppSelector(selectBranding);
  const build = useAppSelector(selectBuild);
  const license = useAppSelector(selectLicense);
  const linkHandling = useAppSelector(selectLinkHandling);
  const nativePlugins = useAppSelector(selectNativePlugins);
  const privateKey = useAppSelector(selectPrivateKey);
  const permissions = useAppSelector(selectPermissions);
  const pushNotifications = useAppSelector(selectPushNotifications);
  const router = useRouter();
  const buildDiff = useAppSelector(selectBuilderDiff);
  const hasChangesPlatform = hasChangeAndroidOrIosKeys(buildDiff);
  const { data: nativePluginsList = [] } = useListPluginsQuery(undefined, {
    skip: !privateKey,
  });
  const { t } = useTranslation();

  const nativePluginsInstalled = useMemo(
    () =>
      nativePluginsList
        .filter(({ gnid }) =>
          Object.keys(license.nativePlugins).includes(camelize(gnid))
        )
        .sort((a, b) => a.numericalOrder - b.numericalOrder),
    [license.nativePlugins, nativePluginsList]
  );

  const showGoogleServicesRequiredWarning = useCallback(() => {
    const plugin = nativePluginsInstalled.find(({ gnid }) => {
      const key = camelize(gnid) as keyof AppBuilderNativePluginsState;
      const state = nativePlugins[key] as GoogleNativePlugin;

      if (!state || !state.config || !state.config.active) return false;

      return (
        (state.requiresGoogleServicesJson &&
          !build.googleServices?.android?.json) ||
        (state.requiresGoogleServiceInfoPlist &&
          !build.googleServices?.ios?.infoPlist)
      );
    });

    if (plugin) {
      const message = t("sections.build.googleServicesPluginWarning");
      toast(`${plugin.name} ${message}`, { type: "warning" });
      return true;
    }
    return false;
  }, [
    build.googleServices?.android?.json,
    build.googleServices?.ios?.infoPlist,
    nativePlugins,
    nativePluginsInstalled,
    t,
  ]);

  const showOneSignalAppIdError = useCallback(() => {
    if (!pushNotifications.oneSignal?.config?.applicationId) return false;
    const appIdFormat =
      /^[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}$/;
    if (appIdFormat.test(pushNotifications.oneSignal?.config?.applicationId)) {
      return false;
    } else {
      toast(t("sections.pushNotifications.invalidAppId"), { type: "error" });
      return true;
    }
  }, [pushNotifications.oneSignal?.config?.applicationId, t]);

  const showGoogleServicesJsonError = useCallback(() => {
    try {
      const googleServicesJson = build.googleServices?.android?.json;

      if (!googleServicesJson) return false;

      const projectInfo = googleServicesJson.project_info;
      const clients = googleServicesJson.client;

      if (!projectInfo || typeof projectInfo !== "object") {
        toast(t("sections.build.importGoogleServicesJsonError"), {
          type: "error",
        });
        return true;
      }

      if (!clients || !Array.isArray(clients)) {
        toast(t("sections.build.importGoogleServicesJsonError"), {
          type: "error",
        });
        return true;
      }

      const isPackageNameValid = clients.some(
        (c) =>
          c.client_info?.android_client_info?.package_name ===
          build.appIdentifiers?.android?.packageName
      );

      if (!isPackageNameValid) {
        toast(t("sections.build.googleServicesJsonError"), { type: "error" });
        return true;
      }

      return false;
    } catch {
      return false;
    }
  }, [
    build.appIdentifiers?.android?.packageName,
    build.googleServices?.android?.json,
    t,
  ]);

  const showSplashIconsWarning = useCallback(() => {
    const has9Patch = [
      "styling.androidLaunchImages.mdpi",
      "styling.androidLaunchImages.hdpi",
      "styling.androidLaunchImages.xhdpi",
      "styling.androidLaunchImages.xxhdpi",
      "styling.androidLaunchImages.xxxhdpi",
      "styling.androidLaunchImagesDark.mdpi",
      "styling.androidLaunchImagesDark.hdpi",
      "styling.androidLaunchImagesDark.xhdpi",
      "styling.androidLaunchImagesDark.xxhdpi",
      "styling.androidLaunchImagesDark.xxxhdpi",
    ].some((key) => {
      const image = get(appConfig, key);
      return typeof image === "string" && image.endsWith(".9.png");
    });
    if (
      has9Patch &&
      !branding.splashScreen?.android?.launchImagesRequireUpdate
    ) {
      toast(t("sections.build.splashIconsWarning"), { type: "warning" });
      return true;
    }
    return false;
  }, [appConfig, branding.splashScreen?.android?.launchImagesRequireUpdate, t]);

  const showUniversalLinksWarning = useCallback(() => {
    if (!linkHandling.universalLinks) {
      return false;
    }
    try {
      for (const url of linkHandling.universalLinks) {
        let completeUrl = url;
        if (!url.startsWith("https://") && !url.startsWith("http://")) {
          completeUrl = `https://${url}`;
        }
        const { pathname } = new URL(completeUrl);
        if (pathname !== "/" && pathname !== "") {
          toast(t("sections.linkHandling.deepLinking.universalLinksError"), {
            type: "warning",
          });
          return true;
        }
      }
    } catch {
      // NO OP
    }
    return false;
  }, [linkHandling.universalLinks, t]);

  const showUrlSchemeProtocolError = useCallback(() => {
    if (!linkHandling.urlSchemeProtocol) return false;

    const regex = /^[a-zA-Z][a-zA-Z\d+\-.]*$/;
    if (regex.test(linkHandling.urlSchemeProtocol)) {
      return false;
    } else {
      toast(t("sections.linkHandling.urlSchemeProtocolError"), {
        type: "error",
      });
      router.push(
        urls.appBuilder(
          privateKey,
          SECTION.linkHandling,
          undefined,
          "url-scheme-protocol"
        ),
        undefined,
        {
          shallow: true,
        }
      );
      return true;
    }
  }, [linkHandling.urlSchemeProtocol, privateKey, router, t]);

  const showAppTrackingTransparencyError = useCallback(() => {
    if (hasChangesPlatform === "android") return false;

    const activePlugins: string[] = [];

    Object.keys(nativePlugins).forEach((key) => {
      const plugin = nativePlugins[key as keyof AppBuilderNativePluginsState];
      if (plugin?.config?.active) {
        activePlugins.push(key);
      }
    });

    Object.keys(pushNotifications).forEach((key) => {
      const notification =
        pushNotifications[key as keyof AppBuilderPushNotificationsState];
      if (
        notification &&
        "config" in notification &&
        notification.config?.active
      ) {
        activePlugins.push(key);
      }
    });

    if (activePlugins.length === 0) return false;

    for (const activePlugin of activePlugins) {
      const plugin = nativePluginsList.find((p) => p.gnid === activePlugin);
      if (plugin && plugin.requiresAtt) {
        if (!permissions.appTrackingTransparency.ios.active) {
          toast(
            t("sections.permissions.showAppTrackingTransparencyError", {
              plugin: plugin.name,
            }),
            { type: "error" }
          );
          return true; // Return true to exit the entire function
        }
      }
    }

    return false;
  }, [
    hasChangesPlatform,
    nativePlugins,
    nativePluginsList,
    permissions.appTrackingTransparency.ios.active,
    pushNotifications,
    t,
  ]);

  return {
    showSplashIconsWarning,
    showGoogleServicesJsonError,
    showGoogleServicesRequiredWarning,
    showUniversalLinksWarning,
    showOneSignalAppIdError,
    showUrlSchemeProtocolError,
    showAppTrackingTransparencyError,
  };
};

export const useValidateRegexValues = () => {
  const privateKey = useAppSelector(selectPrivateKey);
  const linkHandling = useAppSelector(selectLinkHandling);
  const topNavigationBar = useAppSelector(selectTopNavigationBar);
  const bottomTabBar = useAppSelector(selectBottomTabBar);
  const router = useRouter();
  const { t } = useTranslation();

  const showInvalidRegex = useCallback(() => {
    const linkHandlingItems = linkHandling.internalVsExternalLinks.items;
    const autoNewWindowsItems = topNavigationBar.autoNewWindows.items;
    const topNavigationBarItems = topNavigationBar.dynamicTitles.items;
    const bottomTabBarItems = bottomTabBar.bottomTabBarItems.tabSelectionConfig;

    if (
      !linkHandlingItems &&
      !autoNewWindowsItems &&
      !topNavigationBarItems &&
      !bottomTabBarItems
    )
      return false;

    if (linkHandlingItems) {
      for (const item of linkHandlingItems) {
        if (item.custom && item.regex) {
          try {
            RegExp(item.regex);
          } catch {
            toast(t("sections.linkHandling.invalidRegex"), {
              type: "error",
            });
            router.push(
              urls.appBuilder(
                privateKey,
                SECTION.linkHandling,
                undefined,
                "internal-vs-external-links"
              ),
              undefined,
              {
                shallow: true,
              }
            );
            return true;
          }
        }
      }
    }

    if (topNavigationBarItems) {
      for (const item of topNavigationBarItems) {
        if (item.custom && item.regex) {
          try {
            RegExp(item.regex);
          } catch {
            toast(t("sections.linkHandling.invalidRegex"), {
              type: "error",
            });
            router.push(
              urls.appBuilder(
                privateKey,
                SECTION.nativeNavigation,
                "top-naviagition-bar",
                "top-nav-visual-editor"
              ),
              undefined,
              {
                shallow: true,
              }
            );
            return true;
          }
        }
      }
    }

    if (autoNewWindowsItems) {
      const iterateItems = (
        items: AutoNewWindowsValue[] | AutoNewWindowsValue[][]
      ): boolean => {
        for (const item of items) {
          if (Array.isArray(item)) {
            const hasError = iterateItems(item);
            if (hasError) return true;
          } else if (item.custom && item.regex) {
            try {
              RegExp(item.regex);
            } catch {
              return true;
            }
          }
        }
        return false;
      };

      const autoNewWindowsHasInvalidRegex = iterateItems(autoNewWindowsItems);

      if (autoNewWindowsHasInvalidRegex) {
        toast(t("sections.linkHandling.invalidRegex"), {
          type: "error",
        });
        router.push(
          urls.appBuilder(
            privateKey,
            SECTION.nativeNavigation,
            "top-naviagition-bar",
            "auto-new-windows"
          ),
          undefined,
          {
            shallow: true,
          }
        );
        return true;
      }
    }

    if (bottomTabBarItems) {
      for (const item of bottomTabBarItems) {
        if (item.custom && item.regex) {
          try {
            RegExp(item.regex);
          } catch {
            toast(t("sections.linkHandling.invalidRegex"), {
              type: "error",
            });
            router.push(
              urls.appBuilder(
                privateKey,
                SECTION.nativeNavigation,
                "bottom-tab-bar",
                "tab-configuration"
              ),
              undefined,
              {
                shallow: true,
              }
            );
            return true;
          }
        }
      }
    }

    return false;
  }, [
    bottomTabBar.bottomTabBarItems.tabSelectionConfig,
    linkHandling.internalVsExternalLinks.items,
    privateKey,
    router,
    t,
    topNavigationBar.autoNewWindows.items,
    topNavigationBar.dynamicTitles.items,
  ]);

  return {
    showInvalidRegex,
  };
};
