import { parseJSON } from "date-fns";
import { useCallback } from "react";

import {
  ApiBuildProjectArgs,
  ApiSaveProjectArgs,
  useBuildProjectMutation,
  useSaveProjectMutation,
} from "services/api/projects";
import { useAppDispatch, useAppSelector } from "stores";
import {
  selectAndroidAppListingLastUpdated,
  selectIosAppListingLastUpdated,
} from "stores/features/appBuilder/build";
import { AppBuilderState } from "stores/features/appBuilder/constants";
import { metaUpdated } from "stores/features/meta";
import { selectProject, selectPublicKey } from "stores/features/project";
import api from "utils/api";
import { serializeAppBuilder } from "utils/appBuilder";
import { useAppBuilderManager } from "utils/hooks";

export const useSaveProject = () => {
  const [buildProject] = useBuildProjectMutation();
  const [saveProject] = useSaveProjectMutation();
  const { getModifiedAppBuilder, getModifiedAppConfig } =
    useAppBuilderManager();

  const dispatch = useAppDispatch();
  const publicKey = useAppSelector(selectPublicKey);

  const buildProjectModified = useCallback(
    async (args: ApiBuildProjectArgs) => {
      if ("appConfig" in args.data) {
        const appConfig = await getModifiedAppConfig(args.data.appConfig);
        await buildProject({
          privateKey: args.privateKey,
          data: {
            appConfig,
            builderConfig: args.data.builderConfig,
          },
        }).unwrap();
      } else {
        const appBuilder = await getModifiedAppBuilder({
          temporaryUpload: false,
        });
        await buildProject({
          privateKey: args.privateKey,
          data: {
            appBuilder,
            builderConfig: args.data.builderConfig,
          },
        }).unwrap();
      }
      dispatch(metaUpdated({ pendingUploads: {} }));
    },
    [buildProject, dispatch, getModifiedAppBuilder, getModifiedAppConfig]
  );

  const saveProjectModified = useCallback(
    async (args: ApiSaveProjectArgs) => {
      if ("appConfig" in args.data) {
        const appConfig = await getModifiedAppConfig(args.data.appConfig);
        await saveProject({
          privateKey: args.privateKey,
          data: { appConfig },
        }).unwrap();
      } else {
        const appBuilder = await getModifiedAppBuilder({
          temporaryUpload: false,
        });
        await saveProject({
          privateKey: args.privateKey,
          data: { appBuilder },
        }).unwrap();
      }
      dispatch(metaUpdated({ pendingUploads: {} }));
    },
    [dispatch, getModifiedAppBuilder, getModifiedAppConfig, saveProject]
  );

  const getConvertedAppConfig = useCallback(async () => {
    const appBuilder = await getModifiedAppBuilder({
      temporaryUpload: true,
    });
    return api.general.convertAppConfig(
      serializeAppBuilder(appBuilder) as AppBuilderState,
      publicKey
    );
  }, [getModifiedAppBuilder, publicKey]);

  return {
    buildProject: buildProjectModified,
    saveProject: saveProjectModified,
    getConvertedAppConfig,
  };
};

export const useProjectTimestamp = () => {
  const project = useAppSelector(selectProject);

  if (!project.updatedByUser) {
    return {};
  }

  return {
    lastModifiedDate: parseJSON(project.updatedByUser),
    lastModifiedBy: project.lastModifiedBy,
    isModifiedByDeletedUser: project.isModifiedByDeletedUser,
    isModifiedBySuperUser: project.isModifiedBySuperUser,
  };
};

export const useAppListingTimeStamp = () => {
  const iosAppListingLastUpdated = useAppSelector(
    selectIosAppListingLastUpdated
  );
  const androidAppListingLastUpdated = useAppSelector(
    selectAndroidAppListingLastUpdated
  );

  if (!iosAppListingLastUpdated && !androidAppListingLastUpdated) {
    return { iosAppListingDate: undefined, androidAppListingDate: undefined };
  }

  const iosAppListingDate = iosAppListingLastUpdated
    ? new Date(iosAppListingLastUpdated).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : undefined;

  const androidAppListingDate = androidAppListingLastUpdated
    ? new Date(androidAppListingLastUpdated).toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : undefined;

  return {
    iosAppListingDate,
    androidAppListingDate,
  };
};
