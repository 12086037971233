import Plugin from "types/Plugin";

import apiSlice from ".";
import { TagType } from "./constants";
import { camelizedResponseJson } from "./utils";

export type ApiPluginsList = Plugin[];

const pluginsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    listPlugins: build.query<ApiPluginsList, void>({
      providesTags: [TagType.Plugin],
      query: () => ({
        url: "appbuilder/nativePlugins.json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        responseHandler: (response) => camelizedResponseJson(response),
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useListPluginsQuery } = pluginsApi;

export default pluginsApi;
